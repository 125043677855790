import React from 'react';
import { Login as BaseLogin } from '@code-yellow/spider';
import styled from 'styled-components';
import { t } from 'i18n';
import { Image } from 'semantic-ui-react';
import logo from 'image/thetavision.png'

export const BrandingContainer = styled.a`
    display: block;
    text-align: center;
    margin-top: 25px;
    font-weight: bold;
    font-size: 1.25rem;

    color: rgba(0, 0, 0, 0.3);
    transition: color 600ms ease, filter 600ms ease, transform 600ms ease;
    .yellow {
        transition: color 600ms ease;
    }

    &:hover {
        color: #2d303b;
        filter: drop-shadow(0 0 5px #FFF);
        transform: scale(1.05);
        .yellow {
            color: #ebbb12;
        }
    }
`;


export default class Login extends BaseLogin {
  renderBranding() {
    return (
      <BrandingContainer
        href="https://www.thetavision.nl/"
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        <Image src={logo} centered={true} size='tiny' verticalAlign='middle' inline={true}>
        </Image>
        {t('brandingMessage')}
      </BrandingContainer>
    )
  }
}